<template>
  <div class="content md-mt-0 md-pt-0">
    <md-list :md-expand-single="true" class="md-block">
      <md-list-item md-expand>
        <md-icon>filter_alt</md-icon>
        <md-list slot="md-expand">
          <div class="md-layout">
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
            >
              <md-card>
                <md-card-header data-background-color="primary">
                  <h4 class="title">Filtro por localidades</h4>
                </md-card-header>
                <md-card-content
                  class="md-m-0 md-p-0"
                  v-for="(state, index) in statesSearched"
                  :key="index"
                >
                  <md-table
                    class="md-dense"
                    md-sort="name"
                    md-sort-order="asc"
                    v-model="state.cities"
                    md-card
                    md-fixed-header
                  >
                    <md-table-toolbar>
                      <div class="md-toolbar-section-start">
                        <h1 class="md-title">
                          Localidades do {{ state.name }}
                        </h1>
                      </div>
                      <div class="md-toolbar-section-end">
                        <md-field md-clearable>
                          <md-input
                            v-model="termSearch"
                            @click.stop="
                              () => {
                                return true;
                              }
                            "
                            @input="
                              searchByName(
                                states[index].cities,
                                termSearch,
                                index
                              )
                            "
                            placeholder="Buscar..."
                          />
                        </md-field>
                        <md-button
                          class="md-raised md-danger"
                          @click="clearSelect"
                          >Limpar</md-button
                        >
                      </div>
                    </md-table-toolbar>

                    <md-table-empty-state
                      md-label="Não localizado"
                      :md-description="
                        `não foi possivel encontar esta localidade '${'text'}'.`
                      "
                    >
                    </md-table-empty-state>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="ID">{{ item.id }}</md-table-cell>
                      <md-table-cell md-label="Cidades" md-sort-by="name">
                        <md-checkbox
                          v-model="citySelected"
                          :value="item.id"
                          @change="updateLocation('city')"
                        />
                        {{ item.name }}
                      </md-table-cell>
                      <md-table-cell
                        md-label="Microrregiões"
                        md-sort-by="microregion_sesapi.name"
                      >
                        <md-checkbox
                          v-model="microregionSelected"
                          :value="item.microregion_sesapi.id"
                          @change="updateLocation('microregion')"
                        />
                        {{ item.microregion_sesapi.name }}
                      </md-table-cell>
                      <md-table-cell
                        md-label="Mesorregiões"
                        md-sort-by="mesoregion_sesapi.name"
                      >
                        <md-checkbox
                          v-model="mesoregionSelected"
                          :value="item.mesoregion_sesapi.id"
                          @change="updateLocation('mesoregion')"
                        />
                        {{ item.mesoregion_sesapi.name }}</md-table-cell
                      >
                    </md-table-row>
                  </md-table>
                </md-card-content>
              </md-card>
            </div>
            <div
              class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
            >
              <filter-cid-card @update="setCids"></filter-cid-card>
            </div>
          </div>
        </md-list>
      </md-list-item>
    </md-list>

    <div class="md-layout">
      <div
        v-for="item in dataSets"
        :key="item.id"
        class="md-layout-item md-xsmall-size-100 md-size-20"
      >
        <stats-card>
          <template slot="header">
            <div
              class="card-icon md-elevation-5"
              style="margin-right: auto;"
              :style="
                `background: linear-gradient(60deg, rgba(153, 153, 153, 0.5) 1%, ${item.color} 95%)`
              "
            >
              <router-link
                v-if="location_type"
                :to="
                  `/mosaico/sih/details/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}`
                "
              >
                <md-icon>dashboard</md-icon>
              </router-link>
              <router-link
                v-else
                :to="
                  `/mosaico/sih/details/${item.source}/${item.name}/${item.id}/`
                "
              >
                <md-icon>dashboard</md-icon>
              </router-link>
            </div>
            <p class="category">Total AIH {{ item.total | locateNumber }}</p>
            <router-link
              v-if="location_type"
              :to="
                `/mosaico/sih/details/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}`
              "
            >
              <h3 class="title">
                {{ item.year }}
              </h3>
            </router-link>
            <router-link
              v-else
              :to="
                `/mosaico/sih/details/${item.source}/${item.name}/${item.id}/`
              "
            >
              <h3 class="title">
                {{ item.year }}
              </h3>
            </router-link>
          </template>
          <template slot="content">
            <md-button
              v-if="location_type"
              class="md-raised md-info md-m-1 md-block"
              :to="
                `/mosaico/sih/details/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}`
              "
            >
              Detalhes
            </md-button>
            <md-button
              v-else
              class="md-raised md-info md-m-1 md-block"
              :to="
                `/mosaico/sih/details/${item.source}/${item.name}/${item.id}/`
              "
            >
              Detalhes
            </md-button>
            <md-button
              v-if="location_type"
              class="md-raised md-danger md-m-1 md-block"
              :to="
                `/mosaico/sih/map/${item.source}/${item.name}/${item.id}/${location_type}/${location_id}`
              "
            >
              <md-icon>map</md-icon>
              Fluxo
            </md-button>
            <md-button
              v-else
              class="md-raised md-danger md-m-1 md-block"
              :to="`/mosaico/sih/map/${item.source}/${item.name}/${item.id}/`"
            >
              <md-icon>map</md-icon>
              Fluxo
            </md-button>
          </template>
          <template slot="footer">
            <div class="stats">
              <md-icon>today</md-icon>
              Atualizado em: {{ item.updated_at | formatDate }}
            </div>
          </template>
        </stats-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-aih-value">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesAihValues"
                  :series="seriesLinesAihValues"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Faturamento de AHI,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-bar">
                <apexchart
                  type="bar"
                  height="350"
                  :options="optionsBar"
                  :series="seriesBar"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Valores das AIH aprovadas e rejeitas,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-aih">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesAih"
                  :series="seriesLinesAih"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Número de AHI,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-uti">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesPermanence"
                  :series="seriesLinesPermanence"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Diarias,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
    <div class="md-layout">
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-uti">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesUti"
                  :series="seriesLinesUti"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Diarias de UTI,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
      <div class="md-layout-item md-xsmall-size-100 md-size-50">
        <md-card class="md-card-chart">
          <md-card-header class="md-elevation-5" data-background-color="">
            <div class="" style="margin-right: auto;">
              <div id="chart-line-uti">
                <apexchart
                  type="line"
                  height="350"
                  :options="optionsLinesDeath"
                  :series="seriesLinesDeath"
                ></apexchart>
              </div>
            </div>
          </md-card-header>

          <md-card-content>
            <h4 class="title">Série histórica</h4>
            <p class="category" v-if="dataSets.length > 0">
              Óbitos,
              <span class="text-success">
                {{ dataSets[dataSets.length - 1].year }} -
                {{ dataSets[0].year }}
              </span>
            </p>
          </md-card-content>

          <md-card-actions md-alignment="left">
            <div class="stats"></div>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>
import { StatsCard, FilterCidCard } from "@/components";
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    StatsCard,
    apexchart: VueApexCharts,
    FilterCidCard
  },
  data() {
    return {
      selectedCids: [],
      selectedChaptersCids: [],
      selectedGroupsCids: [],
      termSearch: "",
      statesSearched: [],
      location_type: this.$store.state.aih.location_type,
      location_id: this.$store.state.aih.location_id,
      states: [],
      dataSets: [],
      mesoregionSelected: null,
      mesoregionsSeries: [],
      microregionSelected: null,
      microregionsSeries: [],
      citySelected: null,
      citiesSeries: [],
      seriesLinesAih: [],
      optionsLinesAih: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição AIH por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesLinesAihValues: [],
      optionsLinesAihValues: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Faturamento das AIH por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                });
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                });
              }
            }
          }
        }
      },
      seriesLinesUti: [],
      optionsLinesUti: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição de diárias de UTI por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesLinesPermanence: [],
      optionsLinesPermanence: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição de diárias de Internação por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesLinesDeath: [],
      optionsLinesDeath: {
        chart: {
          height: 350,
          type: "line",
          zoom: {
            enabled: true
          }
        },
        colors: [],
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "straight",
          width: 2
        },
        markers: {
          size: 3,
          hover: {
            size: 5
          }
        },
        title: {
          text: "Distribuição Óbitos por mês de competência",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          type: "category",
          categories: Vue.material.locale.shortMonths
        },
        tooltip: {
          y: {
            formatter: value => {
              if (value != undefined) {
                return value.toLocaleString("pt-BR");
              }
            }
          }
        }
      },
      seriesBar: [],
      optionsBar: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        colors: ["#03a9f4", "#FF4560"],
        dataLabels: {
          formatter: function(value) {
            if (value != undefined) {
              return value.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL"
              });
            }
          },
          style: {
            fontSize: "9px"
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.7
          }
        },
        yaxis: {
          style: {
            fontSize: "9px",
            fontWeight: 100
          },
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                });
              }
            }
          }
        },
        tooltip: {
          y: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL"
                });
              }
            }
          }
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                position: "bottom",
                offsetX: -10,
                offsetY: 0
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        xaxis: {
          type: "numeric",
          categories: [],
          tickAmount: "dataPoints",
          tickPlacement: "on",
          labels: {
            formatter: function(value) {
              if (value != undefined) {
                return value.toFixed(0);
              }
            }
          }
        },
        legend: {
          position: "right",
          offsetY: 40
        },
        fill: {
          opacity: 1
        }
      }
    };
  },
  async created() {
    this.location_type = this.$store.state.aih.location_type;
    this.location_id = this.$store.state.aih.location_id;
  },
  computed: {
    loggedIn: function() {
      return this.$store.state.auth.status.loggedIn;
    },
    user: function() {
      return this.$store.state.auth.user;
    }
  },
  async mounted() {
    await this.welcomeMessage();
    this.getAllDataSeries();
  },
  props: {},
  methods: {
    async getAllDataSeries() {
      await this.getDataSets();
      this.getStates();
      this.getSerieBar();
      this.getSerieAihValues();
      this.getSerieAih();
      this.getSerieUti();
      this.getSeriePermanence();
      this.getSerieDeath();
    },
    setFilters(params) {
      if (this.location_type == null) {
        params = this.setFiltersCids(params);
      } else {
        params.location_type = this.location_type;
        params.location_id = this.location_id;

        params = this.setFiltersCids(params);
      }
      return params;
    },
    setFiltersCids(params) {
      if (this.selectedCids.length > 0) {
        let filters_cid = [];
        for (const cid of this.selectedCids) {
          filters_cid.push(cid.code);
        }
        params.column_filters = "diag_princ";
        params.term_filters = filters_cid;
      }

      if (this.selectedChaptersCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }

        for (const chapterCid of this.selectedChaptersCids) {
          params.column_filter_or.push("diag_princ");
          params.term_filter_or_range.push([
            chapterCid.starting_code,
            chapterCid.final_code
          ]);
        }
      }

      if (this.selectedGroupsCids.length > 0) {
        if (params.column_filter_or == undefined) {
          params.column_filter_or = [];
        }
        if (params.term_filter_or_range == undefined) {
          params.term_filter_or_range = [];
        }
        for (const groupCid of this.selectedGroupsCids) {
          params.column_filter_or.push("diag_princ");
          params.term_filter_or_range.push([
            groupCid.starting_code,
            groupCid.final_code
          ]);
        }
      }

      return params;
    },
    welcomeMessage() {
      this.$emit("welcomeMessage", {
        title: "Dados de SIH"
      });
    },
    async getDataSets() {
      try {
        let result;
        let params = {};
        params = this.setFilters(params);
        result = await Vue.$http.get(`v1/dataset/consume/datasus/sih/`, {
          params: params
        });
        this.dataSets = await result.data;
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getSerieBar() {
      if (this.seriesBar.length > 0) {
        this.seriesBar = [];
        this.optionsBar.xaxis.categories = [];
      }

      this.seriesBar.push({
        name: `Aprovados: ${this.dataSets[0].location_name}`,
        data: []
      });
      this.seriesBar.push({
        name: `Rejeitados: ${this.dataSets[0].location_name}`,
        data: []
      });

      for (const item of this.dataSets) {
        //this.optionsBar.colors.push(item.color);
        this.optionsBar.xaxis.categories.push(item.year);
        this.seriesBar[0].data.push(item.approved_val_total);
        this.seriesBar[1].data.push(item.rejected_val_total);
      }
    },
    async getStates() {
      this.states = [];
      this.statesSearched = [];

      try {
        let result = await Vue.$http.get(`v1/city?type=detail&level=sesapi`);

        this.states = result.data;
        this.statesSearched = JSON.parse(JSON.stringify(this.states));
      } catch (e) {
        await this.$store.dispatch("alerts/error", e);
      }
    },
    async getSerieAih() {
      let result;
      if (this.seriesLinesAih.length > 0) {
        this.seriesLinesAih = [];
        this.optionsLinesAih.colors = [];
      }

      let params = {
        per: "mes_cmpt",
        rating: "n_aih",
        operation: "count",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSets) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesAih.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.count);
        });

        this.seriesLinesAih.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieAihValues() {
      let result;
      if (this.seriesLinesAihValues.length > 0) {
        this.seriesLinesAihValues = [];
        this.optionsLinesAihValues.colors = [];
      }

      let params = {
        per: "mes_cmpt",
        rating: "val_tot",
        operation: "sum",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSets) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesAihValues.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.sum);
        });
        this.seriesLinesAihValues.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieUti() {
      let result;
      if (this.seriesLinesUti.length > 0) {
        this.seriesLinesUti = [];
        this.optionsLinesUti.colors = [];
      }

      let params = {
        per: "mes_cmpt",
        rating: "uti_mes_to",
        operation: "sum",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSets) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesUti.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.sum);
        });
        this.seriesLinesUti.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSeriePermanence() {
      let result;
      if (this.seriesLinesPermanence.length > 0) {
        this.seriesLinesPermanence = [];
        this.optionsLinesPermanence.colors = [];
      }

      let params = {
        per: "mes_cmpt",
        rating: "dias_perm",
        operation: "sum",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);

      for (const item of this.dataSets) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesPermanence.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.sum);
        });
        this.seriesLinesPermanence.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    async getSerieDeath() {
      let result;
      if (this.seriesLinesDeath.length > 0) {
        this.seriesLinesDeath = [];
        this.optionsLinesDeath.colors = [];
      }

      let params = {
        per: "mes_cmpt",
        rating: "morte",
        operation: "sum",
        per_page: 12,
        page: 1
      };

      params = this.setFilters(params);
      for (const item of this.dataSets) {
        result = await Vue.$http.get(
          `v1/dataset/consume/serie/datasus/sih/${item.id}/`,
          {
            params: params
          }
        );

        this.optionsLinesDeath.colors.push(item.color);
        let data = [];
        result.data.data.forEach(async itemData => {
          data.push(itemData.sum);
        });
        this.seriesLinesDeath.push({
          name: `${item.year}: ${item.location_name}`,
          data: data
        });
      }
    },
    toLower(text) {
      text = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      return text.toString().toLowerCase();
    },
    searchByName(items, term, index) {
      if (term) {
        items = items.filter(item => {
          if (this.toLower(item.name).includes(this.toLower(term))) {
            return true;
          } else if (
            this.toLower(item.microregion_sesapi.name).includes(
              this.toLower(term)
            )
          ) {
            return true;
          } else if (
            this.toLower(item.mesoregion_sesapi.name).includes(
              this.toLower(term)
            )
          ) {
            return true;
          }
          return false;
        });
      }
      this.statesSearched[index].cities = items;
    },
    async clearSelect() {
      this.mesoregionSelected = null;
      this.microregionSelected = null;
      this.citySelected = null;
      this.location_type = null;
      this.location_id = null;
      this.$store.commit("aih/setLocation", {
        location_type: this.location_type,
        location_id: this.location_id
      });
      this.getAllDataSeries();
    },
    setCids(selectedCids) {
      if (selectedCids.type == "cids") {
        this.selectedCids = [...selectedCids.data];
        this.updateCids();
      } else if (selectedCids.type == "chaptersCids") {
        this.selectedChaptersCids = [...selectedCids.data];
        this.updateCids();
      } else if (selectedCids.type == "groupsCids") {
        this.selectedGroupsCids = [...selectedCids.data];
        this.updateCids();
      }
    },
    updateCids() {
      if (this.selectedCids.length > 0) {
        let filter_cids = [];
        for (const cid of this.selectedCids) {
          filter_cids.push(cid.code);
        }
        this.$store.commit("aih/setCids", {
          filter_cids: filter_cids
        });
      } else {
        this.$store.commit("aih/setCids", {
          filter_cids: null
        });
      }

      if (this.selectedChaptersCids.length > 0) {
        let filter_chapters_cids = [];
        for (const chapterCid of this.selectedChaptersCids) {
          filter_chapters_cids.push([
            chapterCid.starting_code,
            chapterCid.final_code
          ]);
        }
        this.$store.commit("aih/setChaptersCids", {
          filter_chapters_cids: filter_chapters_cids
        });
      } else {
        this.$store.commit("aih/setChaptersCids", {
          filter_chapters_cids: null
        });
      }

      if (this.selectedGroupsCids.length > 0) {
        let filter_groups_cids = [];
        for (const groupCid of this.selectedGroupsCids) {
          filter_groups_cids.push([
            groupCid.starting_code,
            groupCid.final_code
          ]);
        }
        console.log(filter_groups_cids);
        this.$store.commit("aih/setGroupsCids", {
          filter_groups_cids: filter_groups_cids
        });
      } else {
        this.$store.commit("aih/setGroupsCids", {
          filter_groups_cids: null
        });
      }
      this.getAllDataSeries();
    },
    async updateLocation(location_type = null) {
      if (location_type == "city") {
        this.mesoregionSelected = null;
        this.microregionSelected = null;

        this.location_type = location_type;
        this.location_id = this.citySelected;

        if (this.location_id == null) {
          this.location_type = null;
        }

        this.getAllDataSeries();
      } else if (location_type == "microregion") {
        this.mesoregionSelected = null;
        this.citySelected = null;

        this.location_type = location_type;
        this.location_id = this.microregionSelected;

        if (this.location_id == null) {
          this.location_type = null;
        }

        this.getAllDataSeries();
      } else if (location_type == "mesoregion") {
        this.microregionSelected = null;
        this.citySelected = null;

        this.location_type = location_type;
        this.location_id = this.mesoregionSelected;

        if (this.location_id == null) {
          this.location_type = null;
        }

        this.getAllDataSeries();
      } else {
        this.getAllDataSeries();
      }
      this.$store.commit("aih/setLocation", {
        location_type: this.location_type,
        location_id: this.location_id
      });
      return true;
    }
  }
};
</script>
<style lang="scss"></style>
